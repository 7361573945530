<template>
  <div class="text-center">
  <v-container id="search" fluid tag="section" style="padding-bottom: 150px">
    <section>
      <base-material-card>
        <template v-slot:heading>
          <div class="text-h3 font-weight-light">Property Search</div>
        </template>
        <v-row justify="center">
          <v-col cols="12" lg="6">
            <search-address
              @display-parcel="displayParcel"
              @clear-parcel="clearParcel"
              @update-selected-address="updateSelectedAddress"
            />
            <v-spacer />
            <v-btn
              v-if="parcelDetails && canGenerateReport"
              :key="buttonCount"
              class="primary"
              @click.once="generateReport"
            >
              Generate Report
            </v-btn>
          </v-col>
          <v-col cols="12" lg="6">
            <property-map
              :parcel-details="parcelDetails"
              @parcel-details-not-found="() => (isParcelNotFound = true)"
              @parcel-map-render-complete="() => (parcelMapRenderComplete = true)"
            />
          </v-col>
        </v-row>
      </base-material-card>
    </section>
    <v-snackbar v-model="serviceUnavailableForAddress" color="info">
      Service is currently unavailable for chosen address. Please try again
      later.
    </v-snackbar>
    <div class="text-center">
      <out-of-service-dialog
        v-model="isAddressOutOfRange"
        width="500"
        @close-out-of-service-dialog="() => (isAddressOutOfRange = false)"
      />

      <before-launch-dialog
        v-model="isBeforeLaunch"
        width="500"
        @close-before-launch-dialog="() => ( isBeforeLaunch = true )"
      />

      <out-of-credits-dialog
        v-model="isOutOfCredits"
        :selected-address="selectedAddress"
        width="500"
        @close-out-of-credits-dialog="() => (isOutOfCredits = false)"
      />

      <parcel-details-not-found-dialog
        v-model="isParcelNotFound"
        width="500"
        @close-parcel-details-not-found-dialog="
          () => (isParcelNotFound = false)
        "
      />

      <generate-report-dialog
        v-model="showReportGeneratedDialog"
        width="500"
        :user="user"
        @close-report-generated-dialog="closeDialog"
      />
    </div>
  </v-container>
</div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DashboardPagesSearch",
  components: {
    SearchAddress: () => import("@/components/address/SearchAddress"),
    PropertyMap: () => import("@/components/address/PropertyMap"),
    OutOfServiceDialog: () => import("@/components/address/OutOfServiceDialog"),
    OutOfCreditsDialog: () => import("@/components/address/OutOfCreditsDialog"),
    GenerateReportDialog: () =>
      import("@/components/address/GenerateReportDialog"),
    ParcelDetailsNotFoundDialog: () =>
      import("@/components/address/ParcelDetailsNotFoundDialog"),
    BeforeLaunchDialog: () =>
      import("@/components/address/BeforeLaunchDialog")
  },
  data: () => ({
    parcelDetails: null,
    isAddressOutOfRange: false,
    isOutOfCredits: false,
    isBeforeLaunch: process.env.VUE_APP_SOFTLAUNCH_MODE === "true",
    isParcelNotFound: false,
    parcelMapRenderComplete: false,
    selectedAddress: "",
    serviceUnavailableForAddress: false,
    showReportGeneratedDialog: false,
    buttonCount: 1,
  }),

  computed: mapState({
    canGenerateReport: (state) => state.report.report.canGenerateReport,
    user: (state) => state.user.user,
    roles: (state) => state.user.roles,
    credits: (state) => state.user.credits,
    currentParcel: (state) => state.address.address.currentParcel,
  }),

  watch: {
    parcelMapRenderComplete(val) {
      if (this.$route.query && this.$route.query.selectedAddress && this.$route.query.autoGenerate === "true"){
          // console.log(new Date()+" - Waiting to then call generate");    
          delete this.$route.query.autoGenerate;
          delete this.$route.query.selectedAddress;
          setTimeout(()=>{
            // console.log(new Date()+" - Waited now calling generate");
            this.$store.dispatch("retrieveSubscriptions");
            this.$store.dispatch("retrieveCredits").then((response) => {
              // console.log("retrieved credtis done so now calling generateReport");
              this.generateReport();
            });
          }, 3000);          
      }
    },
  },

  created() {
    this.$store.dispatch("retrieveSubscriptions");
    this.$store.dispatch("retrieveCredits");
    if (this.$route.query && this.$route.query.selectedAddress && this.$route.query.autoGenerate) {
      if (this.$route.query.autoGenerate === "true") {
        this.displayParcel(); // kick off the find of the parcel
      }
    }
  },

  methods: {
    displayParcel() {
      this.$store.dispatch("setCanGenerateReport", false).then(() => {
        const parcel = this.currentParcel;
        if (parcel.LocalGovernmentArea) {
          if (parcel.LocalGovernmentArea.Code === "1000") {
            this.parcelDetails = parcel;
          } else {
            this.isAddressOutOfRange = true;
          }
        } else {
          this.serviceUnavailableForAddress = true;
        }
      });
    },

    clearParcel() {
      this.parcelDetails = null;
    },

    closeDialog() {
      this.showReportGeneratedDialog = false;
      this.buttonCount++;
    },

    updateSelectedAddress(value){
      this.selectedAddress = value;
    },

    generateReport() {
      if (this.user && this.user.emailVerified) {
        if (this.roles === "business" || this.roles === "orgUser" || this.credits > 0) {
          this.user.getIdToken().then((token) => {
            this.$store.dispatch("generateReport", {
              parcelDetails: this.parcelDetails,
              userId: this.user.uid,
              userEmail: this.user.email,
              token,
            });
          });
        } else {
          this.isOutOfCredits = true;
          this.buttonCount++;
          return;
        }
      }
      this.showReportGeneratedDialog = true;
    },
  },
};
</script>
